<script>
import { round, sum } from 'lodash'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from '@/vue-chartjs'
import { formatCurrency } from '@/misc/filters'

export default {
  extends: Doughnut,
  props: {
    datasets: Array,
    labels: Array,
    currency: String,
    currencyFormat: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (item, { labels, datasets }) => {
              const label = labels[item.index]
              const value = datasets[item.datasetIndex].data[item.index]
              const amount = formatCurrency(
                value,
                this.currency,
                this.currencyFormat
              )
              const percentAmount = (100 * value) / this.total
              return `${label}: ${amount} (${round(percentAmount, 1)}%)`
            }
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: 'rgba(255,255,255,.6)',
            borderColor: 'white',
            borderRadius: 5,
            borderWidth: 0,
            color: 'rgba(0,0,0,.8)',
            font: {
              weight: 'bold'
            },
            padding: 6,
            display: ({ dataIndex, dataset }) => {
              const value = dataset.data[dataIndex]
              let percentAmount
              if (this.$data._chart) {
                const meta = this.$data._chart.getDatasetMeta(0)
                const shownTotal = this.datasets[0].data
                  .map((x, idx) => {
                    if (meta.data[idx] && meta.data[idx].hidden) {
                      return 0
                    }
                    return x
                  })
                  .reduce((x, y) => x + y)
                percentAmount = (100 * value) / shownTotal
              } else {
                percentAmount = (100 * value) / this.total
              }
              return percentAmount > 5
            },
            formatter: value => {
              const percentAmount = (100 * value) / this.total
              return `${round(percentAmount, 1)}%`
            }
          }
        }
      }
    }
  },
  computed: {
    total() {
      const { data } = this.datasets[0]
      return sum(data)
    }
  },
  watch: {
    datasets: {
      handler() {
        this.doRender()
      }
    }
  },
  mounted() {
    this.addPlugin(ChartDataLabels)
    this.doRender()
  },
  methods: {
    doRender() {
      if (!this.datasets || !this.labels) return
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.datasets
        },
        this.options
      )
    }
  }
}
</script>
