<template lang="pug">
.chart
  b-loading(:active="loading" :is-full-page="true" :can-cancel="false")
  simple-pie(v-bind='data' v-if='data')
</template>

<script>
import frameData from '@/misc/embed-transport'
import SimplePie from '@/components/charts/SimplePie.vue'

export default {
  name: 'EmbedPie',
  components: {
    SimplePie
  },
  data() {
    return { loading: true, data: null }
  },
  mounted() {
    frameData.then(
      ({ labels, colors, values, currency, currencyFormat = {} }) => {
        this.loading = false
        this.data = {
          currency,
          currencyFormat,
          labels,
          datasets: [
            {
              backgroundColor: colors,
              data: values
            }
          ]
        }
      }
    )
    document.getElementsByTagName('html')[0].style = 'overflow: hidden'
  },
  metaInfo: {
    title: 'Embedded Pie Chart'
  }
}
</script>
<style scoped lang="sass">
.chart
  position: relative
</style>
